import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="applicants--loan-details"
export default class extends Controller {
  connect() {
    $("form").submit(function(){
      $('input[type=submit]').click(function(event){
        event.preventDefault();
      });
    });
    validatorLoanDetail();
    $('#loan_amount_formatter').keyup(function(){
      numberFormatterWithDecimal($(this))
      $('#applicant_loan_amount').val(this.value.replace(/,/g , ''))
    });

    $('#loan_amount_formatter').blur(function(){
      numberFormatterWithDecimal($(this))
      $('#applicant_loan_amount').val(this.value.replace(/,/g , ''))
    });

   $('#applicant_loan_amount').focus(function(){
      $('#loan_amount_formatter').val($('#applicant_loan_amount').val())
      $('#loan_amount_formatter').focus()
    });

    $("#loan_amount_formatter").keyup(function(){
      $('#applicant_loan_amount').focus();
    })

    const modal = $("#incomeConfirmationModal");
    $("#applicant_gross_monthly_income").on("blur", function () {
      console.log('monthly')
      const income = parseFloat($(this).val());
      // If income exceeds 20,000 and not yet confirmed
      if (income > 20000) {
        modal.modal("show"); // Show the modal
      }
    });

    // Handle "Yes" button click
    $("#confirmIncome").on("click", function () {
      modal.modal("hide"); // Hide the modal
    });

    // Handle "No" button click
    $("#declineIncome").on("click", function () {
      modal.modal("hide"); // Just hide the modal
      $("#applicant_gross_monthly_income").focus(); // Refocus the input for correction
    });

    if($("#co_loan_detail_v3").length == 1){
      var minIncome = parseInt($('#min_income').val())
      $("#co_loan_detail_v3").validate({
        rules: {
          "applicant[co_gross_annual_income]": {
            required: true,
            amountregx: true,
            maxlength: 9,
            minIncomeZero: true,
            annual_gross_max: true
          }
        },
       messages: {
        "applicant[co_gross_annual_income]": {
            required: 'Please enter gross income amount.',
            amountregx: 'Please enter gross income amount.',
            minIncomeZero: 'Sorry, annual gross income must be greater than zero.',
            maxlength: 'Sorry, annual gross income must be less than 1000000.',
            annual_gross_max: 'Sorry, annual gross income must be less than 1000000.'
          }
        }
      });
    }

    if($('#loan_detail_v3').length == 1){
      var min_loan_amount = parseInt($('#min_loan_amount').val())
      var max_loan_amount = parseInt($('#max_loan_amount').val())
      var minIncome = parseInt($('#min_income').val())
      $('#loan_detail_v3').validate({
        rules: {
          'applicant[loan_amount]': {
            required: true,
            number: true,
            min: min_loan_amount,
            max: max_loan_amount
          },
          'applicant[loan_purpose]': {
            required: true
          },
          'applicant[gross_annual_income]': {
            required: true,
            amountregx: true,
            minIncomeZero: true,
            maxlength: 9,
            annual_gross_max: true
          }
        },
        messages: {
          'applicant[loan_amount]': {
            required: 'Please enter requested amount.',
            number: 'Please enter requested amount.',
            min: 'Sorry, requested amount must be greater than ' + min_loan_amount + '.',
            max: 'Sorry, requested amount must be between ' + min_loan_amount + ' and ' + max_loan_amount + '.'
          },
          'applicant[loan_purpose]': {
            required: 'Please select Purpose.'
          },
          'applicant[gross_annual_income]': {
            required: 'Please enter gross income amount.',
            amountregx: 'Please enter gross income amount.',
            minIncomeZero: 'Sorry, annual gross income must be greater than zero.',
            annual_gross_max: 'Sorry, annual gross income must be less than 1000000.',
            maxlength: 'Sorry, annual gross income must be less than 1000000.'
          }
        }
      });
    }

    if($('#applicant_gross_monthly_income').data('dtc-lender')) {
      $("#applicant_gross_monthly_income").rules("add", {
        required: true,
        amountregx: true,
        minIncomeZero: true,
        monthly_gross_max: true,
        messages: {
          required: 'Please enter gross monthly income amount.',
          amountregx: 'Please enter gross monthly income amount.',
          minIncomeZero: 'Sorry, monthly  gross income must be greater than zero.',
          monthly_gross_max: 'Sorry, monthly gross income must be less than 40000.'
        }
      });
    }else{
      $("#applicant_gross_monthly_income").rules("add", {
        required: true,
        amountregx: true,
        minIncomeZero: true,
        maxlength: 9,
        annual_gross_max: true,
        messages: {
          required: 'Please enter gross monthly income amount.',
          amountregx: 'Please enter gross monthly income amount.',
          minIncomeZero: 'Sorry, monthly  gross income must be greater than zero.',
          annual_gross_max: 'Sorry, monthly gross income must be less than 1000000.',
          maxlength: 'Sorry, monthly gross income must be less than 1000000.'
        }
      });
    }

    submitBtnEnableDisable();

    $('input').keyup(function(){
      submitBtnEnableDisable();
    })

    $('input').change(function(){
      submitBtnEnableDisable();
    });

    // $('#applicant_gross_annual_income, #applicant_co_gross_annual_income').keyup(function(){
    //   numberFormatter($(this))
    // });

    // $('#applicant_gross_annual_income, #applicant_co_gross_annual_income').blur(function(){
    //   numberFormatter($(this))
    // });

    $('#co_loan_detail_v3, #loan_detail_v3').mouseover(function function_name(argument) {
      submitBtnEnableDisable();
    });
  }


}

window.validatorLoanDetail = function(){
  $.validator.addMethod("amountregx", function(value, element) {
    return this.optional(element) || /^[0-9,]+$/.test(value);
  });

  $.validator.addMethod("minIncomeZero", function(value, element) {
    return this.optional(element) || Number(value.replace(/,/g, '')) >= 1;
  });

  $.validator.addMethod("minIncome", function(value, element) {
    var incomeAmount = $('#min_income').val()
    if(incomeAmount.length > 0){
      incomeAmount = Number(incomeAmount)
    }else{
      incomeAmount = 10000
    }
    return this.optional(element) || Number(value.replace(/,/g, '')) >= incomeAmount;
  });

  $.validator.addMethod("annual_gross_max", function(value, element) {
    return this.optional(element) || Number(value.replace(/,/g, ''))<= 1000000;
  });

  $.validator.addMethod("monthly_gross_max", function(value, element) {
    return this.optional(element) || Number(value.replace(/,/g, ''))<= 40000;
  });
  
}


