import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--spi-payment-statuses-report"
export default class extends Controller {
  connect() {
    $('#select_all').change(function() {
      $('td.select-item input').prop('checked', this.checked)
      if(this.checked){
        $('td.select-item').parent().addClass('selected-row')
      }else{
        $('td.select-item').parent().removeClass('selected-row')
      }
      $(this).attr('data-selected-ids', selectedAppIds().join(','))
      $('.batch-action-section button').text($('.batch-action-section button').data('text') + ` (${selectedAppIds().length}) `)
    })

    $('#loading_data').hide()
    if($('#payment_status_table_section').length == 1){
      $('#payment_status_table_section').DataTable({
        paging: false,        // Disable pagination
        ordering: false,      // Disable sorting
        info: false,          // Disable table information display
        searching: true       // Enable search functionality
      });
      $('#payment_status_table_section_filter').detach().prependTo('#searchContainer');
      $('#payment_status_table_section_filter label').css('display', 'inline-flex');
      $('#payment_status_table_section_filter input').css('margin-left', '10px');
    }
    
  }


  member_notified_changes(e){
    console.log('asdasdasdasd')
    let element = e.target
    var csrfToken = $('meta[name="csrf-token"]').attr('content')
      $.ajaxSetup({
        headers: {
          'X-CSRF-Token': csrfToken
        }
      });

    var applicant_id = $(element).data('applicant-id')
    var payment_date = $(element).data('payment-made')
    var txns_ids = $(element).data('txns-ids')
    var status   = $(element).data('status')
    var remark   = $(element).data('remark')
    var member_notified = element.checked
    console.log(member_notified)

    $.ajax({
      type: 'PUT',
      url: '/admin/spi_payment_statuses/' + applicant_id +'/update_member_notified',
      data: { "applicant_id": applicant_id,  "payment_date": payment_date, "txns_ids": txns_ids, "status": status, "remark": remark, "member_notified": member_notified },
      dataType: "json",
      success: function(data) {
      }
    })
  }

  highlight(e) {
    let element = e.target
    if(element.checked){
      $(element).parent().parent().addClass('selected-row')
    }else{
      $(element).parent().parent().removeClass('selected-row')
    }

    $('#select_all').attr('data-selected', selectedAppIds().join(','))
    $('.batch-action-section button').text($('.batch-action-section button').data('text') + ` (${selectedAppIds().length}) `)
  }

  batchActionOpen(){
    $('#batch_pymt_txns_ids').val(selectedAppIds().join(','))
    $('#applicant_ids').val(applicant_ids().join(','))
    $('#batchActionModal .modal-body p b').text(selectedAppIds().length)
  }

  batchActionUpdate(e){
    $('.modal-backdrop').remove()
  }

}

window.selectedAppIds = function(){
  let data = []
  $('td.select-item input:checked').each(function(_index, item) {
    data.push(item.value)
  })

  return data
}


window.applicant_ids = function(){
  let applicant_ids = []
  $('td.select-item input:checked').each(function(_index, item) {
    applicant_ids.push($(item).data('applicant-id'))
  })

  return applicant_ids
}
